@use "assets/styles/global" as *;

$modal-background-color: #f7f7fc;

.token-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 0;

  @include md {
    padding: 10%;
  }

  .token-title-container {
    text-align: center;
    margin-bottom: 3rem;

    .logo {
      max-height: 40px;
      margin-bottom: 0.5rem;
    }

    .token-title {
      font-weight: 700;
      font-size: clamp(22px, 3.5rem, 58px);
    }
  }
}
.token-modal {
  border-radius: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;
  width: 90%;

  @include md {
    width: 80%;
  }

  .modal-header {
    padding: 1.5rem 1.2rem;
    background-color: $brand-color;

    @include md {
      padding: 1.5rem 2rem;
    }
  }

  .modal-header-title {
    color: $brand-text-color;
    text-align: center;
    font-size: clamp(16px, 1.5rem, 24px);
    font-weight: 700;
  }

  .modal-body {
    padding: 2rem 1.5rem;
    background-color: $background-color;

    @include md {
      padding: 2rem 3rem;
    }
  }
  .input-title {
    font-size: 18px;
    width: 100%;
    font-weight: 700;
  }

  .token-input {
    margin: 2rem 0;
    border: 1px solid $border-color;

    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 6px;
    font-weight: 300;
    font-size: 16px;

    color: $text-color;
    font-family: $main-font;

    &:focus {
      outline: none;
      border-color: black;
    }
    &:hover {
      border-color: black;
    }
  }

  .actions-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
