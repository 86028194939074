@use "assets/styles/global" as *;

.page-header {
  padding: 1rem 0;
  text-align: center;
  background-color: $background-color;
  box-shadow: 0px 6px 12px $box-shadow-color;

  .header-title {
    font-size: 27px;
    padding: 0 4rem;
  }
}

.page-content {
  max-width: 100vw;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;

  @include lg {
    max-width: 2400px;
    padding-left: 80px;
    padding-right: 80px;
  }
}

.dashboard-section {
  display: flex;
  flex-wrap: wrap;

  gap: 2rem;
  margin-bottom: 2rem;

  @include lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.dashboard-element {
  background-color: $background-color;
  box-shadow: 0px 0px 40px #50468833;
  border-radius: 24px;
  padding: 30px 20px;

  @include lg {
    padding: 30px 40px;
  }
}
