@use "assets/styles/global" as *;

.graph-label {
  display: flex;
  align-items: center;
}
.graph-label-text {
  font-size: 14px;

  .label-highlight {
    font-size: 18px;
  }

  strong {
    font-weight: bold;
  }
}
