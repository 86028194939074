$background-color: white;
$text-color: #292e42;

$error-color: #d32f2f;

$brand-color: #0051ff;
$brand-text-color: white;

$box-shadow-color: #42467e1f;
$border-color: #dcdada;

// Font
$main-font: "Open Sans", sans-serif;
