@use "assets/styles/global" as *;

.dashboard-section.weekday-graphs-section {
  @include sm-md {
    display: none;
  }
}

.graph-labels {
  margin: 1rem;

  .graph-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    .toggle {
      margin-left: 2rem;
    }
  }
}
