@use "assets/styles/global" as *;

.line-graph-container {
  .graph-title {
    font-size: 20px;
    margin: 1rem 0;
  }

  .line-graph {
    width: 100%;
    height: 250px;
  }
}
