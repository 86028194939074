@use "assets/styles/global" as *;

.card {
  display: flex;
  align-items: center;
  gap: 36px;

  @include sm-md {
    width: 100%;
    flex-direction: column;
  }

  @include lg {
    flex-direction: row;
    justify-content: space-between;
  }

  .card-icon-container {
    flex-shrink: 0;
    width: 51px;
    height: 51px;

    background-color: #ebf1ff;
    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-text-container {
    flex-grow: 1;

    @include sm-md {
      text-align: center;
    }
  }

  .result {
    font-size: 25px;
  }
}
