@use "global" as *;

html,
body,
#root {
  font-family: $main-font;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: $main-font;
  color: $text-color;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

p {
  font-weight: 400;
}

a {
  font-weight: 500;
}
