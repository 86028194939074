@use "assets/styles/global" as *;

$toggleWidth: 40px; // 60px;
$toggleHeight: calc($toggleWidth / 2);

$switchGap: 3px;
$switchSize: calc($toggleHeight - ($switchGap * 2));

.toggle {
  display: flex;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $toggleWidth;
    height: $toggleHeight;
    display: block;
    border-radius: $toggleHeight;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: $switchGap;
    left: $switchGap;
    width: $switchSize;
    height: $switchSize;
    background: #fff;
    border-radius: $switchSize;
    transition: 0.3s;
  }

  input:checked + label {
    background: $brand-color;
  }

  input:checked + label:after {
    left: calc(100% - $switchGap);
    transform: translateX(-100%);
  }
}
