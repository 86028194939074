@use "assets/styles/global" as *;

.card.current-week-highlight-card {
  background-color: #ebf1ff;
  box-shadow: 0px 0px 12px #292e4233;
  border-radius: 12px;
  padding: 16px 22px;
  gap: 18px;

  @include sm-md {
    width: 80%;
  }

  .card-icon-container {
    width: 40px;
    height: 40px;
    background-color: transparent;
  }

  .description {
    font-size: 12px;
    color: $brand-color;
  }
  .result {
    font-size: 20px;
    color: $brand-color;
  }
}
